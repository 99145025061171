import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Card,
  Progress,
  Image,
  Typography,
  Badge,
  Tooltip,
} from "antd";
import styled from "@emotion/styled";
import {
  enhanceProduct,
  EntityMetaData,
  Product,
  WorkTask,
} from "interfaces/apiTypes";
import { ILanguage } from "interfaces";
import {
  ColumnConfig,
  DEFAULT_LIST_COL_HEIGHT_PX,
  SimpleListView,
} from "components/listViews/simpleListView";
import { FilterGroup, FilterItem, FilterOperatorEnum, LogicalOperatorEnum, SortOrderEnum } from "interfaces/baseTypes";
import useProductOpenApi from "apiClients/useProductOpenApiClientV2";
import { useLocaleStore } from "stores/localeStore";
import { RefreshCcw, XCircle } from "lucide-react";
import NoImage from "../../assets/no-image.png";
import { RenderShippingSvg } from "components/Svg/RenderShippingSvg";
import {
  convertFilterStringToQueryParams,
  formatDate,
  parseFiltersToString,
  parseStringToFilters,
} from "utility/helper";
import { Dropdown, Tag } from "antd/lib";
import { PRODUCTS_QUERY_KEY } from "utility/constants";
import Search from "antd/es/input/Search.js";
import { BaseKey } from "@refinedev/core";
import { useTranslation } from "react-i18next";
import { useMultiMenuItems } from "components/hooks/multiMenuItems";
import { useModal } from "@refinedev/antd";
import { useKassanovaSync } from "components/hooks/kassanovaSync";
import { useTextRewrite } from "components/hooks/textRewrite";
import ImportFileModal from "components/modals/importFile";
import { MultiGenerateTextModal } from "components/modals/multiGenerateText";
import { MultiEditProductsModal } from "components/modals/multiEditProducts";
import KassanovaFetchModal from "components/modals/kassanovaFetch";
import WorkTaskSelect from "components/common/select/workTaskSelect";
import ProductStatusFilterTabs from "components/tabs/productStatusFilterTabs";
import { useLocation, useNavigate } from "react-router-dom";
// import MagicButton from "components/buttons/magicButton";
import TopLabel from "components/common/topLabel";
import useWorkTaskOpenApi from "apiClients/useWorkTaskOpenApiClient";

const ImageWrapper = styled.div`
  .ant-image-img {
    object-fit: contain;
  }
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .top-header {
    min-height: 32px;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  .sub-header {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  .header-buttons {
    display: flex;
    width: 100%;
    gap: 0.75rem;
  }

  .ant-badge {
    width: 100%;
    height: 100%;
  }
`;

const StyledCard = styled(Card)`
  .ant-page-header-heading-left {
    display: none;
  }

  .ant-page-header-heading {
    display: none;
  }

  .ant-table-tbody > .ant-table-row .ant-table-cell:hover {
    cursor: pointer;
  }
  .default-cursor {
    cursor: default;
  }
  .pointer-cursor {
    cursor: pointer;
  }
  .auto-cursor {
    cursor: auto;
  }
  /* .ant-progress {
    min-width: 60px !important;
    max-width: 75px !important;
  } */
  .selected-products-count {
    min-width: 51px;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
  }

  .custom-badge-empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border-radius: 10px;
    color: #b8b8b8;
  }
`;

const TableTitle = styled(Typography.Title)`
  margin-bottom: 0.75rem !important;
`;

const TextSpan = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-width: 200px;
`;

const ProductProgress = styled(Progress)`
  opacity: 0.8;
  min-width: 60px !important;
  max-width: 75px !important;
  :hover {
    opacity: 1;
  }
`;

const getProductColumns = (
  activeLanguage: ILanguage | null
): ColumnConfig<Product>[] => [
  {
    header: "",
    accessorKey: "images",
    cell: ({ row }) => (
      <ImageWrapper>
        <Image
          fallback={NoImage}
          width={60}
          height={60}
          className="prevent-row-click"
          src={enhanceProduct(
            row.original,
            activeLanguage?.id
          )?.getImageUrl()}
        />
      </ImageWrapper>
    ),
    size: 60,
  },
  {
    header: "Namn",
    accessorKey: "name",
    cell: ({ row }) => (
      <span>{enhanceProduct(row.original, activeLanguage?.id)?.getName()}</span>
    ),
    size: 200
  },
  {
    header: "ArtNr",
    accessorKey: "mainItemNo",
    cell: ({ row }) => (
      <span>{enhanceProduct(row.original, activeLanguage?.id)?.getItemNumber()}</span>
    ),
    size: 100,
    sort: true,
  },
  {
    header: "Varumärke",
    accessorKey: "brand",
    cell: ({ row }) => (
      <Tag>
        {enhanceProduct(row.original, activeLanguage?.id)?.getBrandName()}
      </Tag>
    ),
    size: 100,
    sort: true,
  },
  {
    header: "Varugrupp",
    accessorKey: "groupName",
    cell: ({ row }) => (
      <span>
        {enhanceProduct(
          row.original,
          activeLanguage?.id
        )?.getItemGroupName()}
      </span>
    ),
    size: 100,
  },
  {
    header: "Fraktklass",
    accessorKey: "shippingMethod",
    cell: ({ row }) => (
      <RenderShippingSvg
        shippingType={
          enhanceProduct(row.original, activeLanguage?.id)
            ?.shippingMethod ?? ""
        }
      />
    ),
    size: 100,
    sort: true,
  },
  {
    header: "Beskrivning",
    accessorKey: "description",
    cell: ({ row }) => (
      <TextSpan>
        {enhanceProduct(
          row.original,
          activeLanguage?.id
        )?.getDescription?.()}
      </TextSpan>
    ),
    size: 200,
  },
  {
    header: "Skapad",
    accessorKey: "createdAt",
    cell: ({ row }) => (
      <TextSpan>{formatDate(row.original.createdAt) ?? "N/A"}</TextSpan>
    ),
    size: 100,
    sort: true,
  },
  {
    header: "Lev besk",
    accessorKey: "longDescriptionSupplier",
    cell: ({ row }) => (
      <TextSpan>
        {enhanceProduct(
          row.original,
          activeLanguage?.id
        )?.getLongDescriptionSupplier()}
      </TextSpan>
    ),
    size: 100,
    visible: false,
  },
  {
    header: "Publicerad",
    accessorKey: "isPublished",
    cell: ({ row }) => (
      <Tag
        color={
          enhanceProduct(row.original, activeLanguage?.id)?.isPublished
            ? "green"
            : "red"
        }
      >
        {enhanceProduct(row.original, activeLanguage?.id)?.isPublished
          ? "Publicerad"
          : "Ej Publicerad"}
      </Tag>
    ),
    size: 100,
  },
  {
    header: "Säljkanaler",
    accessorKey: "salesChannels",
    cell: ({ row }) => (
      <div style={{ display: "flex" }}>
        {enhanceProduct(row.original, activeLanguage?.id)
          ?.getSaleChannels()
          ?.map((sc) => (
            <Tag key={sc.id} color={"blue"}>
              {sc?.name?.slice(0, 2)}
            </Tag>
          ))}
      </div>
    ),
    size: 150,
  },
  {
    header: "Status",
    accessorKey: "completeness",
    cell: ({ row }) => {
      const completeness = enhanceProduct(
        row.original,
        activeLanguage?.id
      )?.getCompleteness();
      return (
        <Tooltip
          title={completeness?.message ?? "Statusinformation saknas"}
          className="auto-cursor"
          color={
            completeness?.message
              ? completeness?.percentage < 100
                ? "blue"
                : "green"
              : ""
          }
        >
          <ProductProgress
            className="auto-cursor"
            percent={completeness?.percentage ?? 0}
            size="small"
          />
        </Tooltip>
      );
    },
    size: 100,
  },
];

const getDefaultFilters = (): FilterGroup => ({
  operator: LogicalOperatorEnum.AND,
  value: [
    {
      operator: LogicalOperatorEnum.AND,
      value: [
        {
          fieldName: "productStatus",
          operator: FilterOperatorEnum.EQUALS,
          value: "0",
        }
      ],
    }
  ],
});

export const ProductList: React.FC = () => {
  // States
  const [metadata, setMetadata] = useState<EntityMetaData | undefined>(
    undefined
  );
  const [defaultWorkTaskId, setDefaultWorkTaskId] = useState<
    number | undefined
  >(undefined);
  const [workTaskHasNoFilters, setWorkTaskHasNoFilters] = useState<
    boolean | undefined
  >(undefined);
  const [kassanovaFetchModalOpen, setKassanovaFetchModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // Hooks
  const navigate = useNavigate();
  const { activeLanguage } = useLocaleStore();
  const { kassanovaSyncProductItems } = useKassanovaSync();
  const { rewriteText } = useTextRewrite();
  const { menuItems } = useMultiMenuItems();
  const [filterGroup, setFilterGroup] = useState<FilterGroup>(getDefaultFilters());
  const prevFilterGroupRef = useRef<string>();
  const [initWorkTaskLoaded, setInitWorkTaskLoaded] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const { getProducts, getMetadata, loading } = useProductOpenApi();
  const {
    show: showTranslateContextModal,
    close: closeTranslateContextModal,
    modalProps: translateContextModalProps,
  } = useModal();
  const {
    show: showMultiEditProductsModal,
    close: closeMultiEditProductsModal,
    modalProps: multiEditProductsModalProps,
  } = useModal();
  const {
    show: showImportModal,
    close: closeImportModal,
    modalProps: importModalProps,
  } = useModal();

  const location = useLocation();

  const { getWorkTaskById } = useWorkTaskOpenApi();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const defaultQuery = queryParams.get("default-query");
    const taskParams = queryParams.get("worktask");
    const searchParam = queryParams.get("search");
  
    // Create a new filter group instead of copying the reference
    let filterGroupCopy = {
      operator: LogicalOperatorEnum.AND,
      value: [...filterGroup.value] // Create a new array
    };

    // Handle default query filters
    if(defaultQuery){
      const defaultFilterGroup = parseStringToFilters(defaultQuery);
      const defaultGroup = filterGroupCopy.value[0] as FilterGroup;
      defaultGroup.value = defaultFilterGroup.value as FilterItem[];
    }

    setFilterGroup(filterGroupCopy);

    // Handle search parameter
    if (searchParam) {
      setSearchValue(searchParam);
    }


    if(taskParams){
      setDefaultWorkTaskId(prev => prev = parseInt(taskParams));
    }
  }, []);

  useEffect(() => {
    if (JSON.stringify(filterGroup) === prevFilterGroupRef.current) {
      return;
    }

    const defaultFilter = filterGroup.value[0] as FilterGroup;
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set("default-query", parseFiltersToString(defaultFilter));
    
    if (defaultWorkTaskId) {
      currentParams.set("worktask", defaultWorkTaskId.toString());
    } else {
      currentParams.delete("worktask");
    }

    navigate(`${window.location.pathname}?${currentParams.toString()}`);

    prevFilterGroupRef.current = JSON.stringify(filterGroup);

  }, [filterGroup]);

  // Effects
  useEffect(() => {
    getMetadata().then((metadata) => {
      if (!metadata) {
        return;
      }
      setMetadata(metadata);
    });
  }, []);

  useEffect(() => {
    const fetchWorkTask = async () => {
      if (defaultWorkTaskId) {
        const workTask = await getWorkTaskById(defaultWorkTaskId);
        handleWorkTaskChange(workTask?.item ?? undefined);
      }
      if(searchValue){
        handleSearch(searchValue);
      }
    };
    if(!initWorkTaskLoaded){
      fetchWorkTask();
    }
  }, [defaultWorkTaskId, searchValue]);

  // Event handlers
  const onMultiButtonClick = (e: any) => {
    // setTableIsLoading(true);
    if (e.key == 1) {
      showTranslateContextModal();
    } else if (e.key == 2) {
      showMultiEditProductsModal();
    } else if (e.key == 3) {
      // console.log(
      //   "kassanovaSyncProductItems event (",
      //   "ROWKEYS/IDS",
      //   selectedRowKeys
      // );
      kassanovaSyncProductItems({
        productItemIds: selectedRowKeys as BaseKey[],
      });
    }
    // setTableIsLoading(false);
  };

  const onImportModalFinish = () => {
    closeImportModal();
  };

  const onTextRewriteFinish = async (data: any) => {
    closeTranslateContextModal();
    rewriteText(selectedRowKeys, data?.context);
    setSelectedRowKeys([]);
  };

  const onMultiEditProductsModalFinish = async (clearAllKeys: boolean) => {
    closeMultiEditProductsModal();
    if (clearAllKeys) {
      setSelectedRowKeys([]);
    }
  };

  const onSelectChange = (selectedRowKeys: Product[]) => {
    setSelectedRowKeys(selectedRowKeys?.map((item) => item.id));
  };

  // Filter logic
  const clearFilter = () => {
    setDefaultWorkTaskId(undefined);
    setFilterGroup(getDefaultFilters());
    setWorkTaskHasNoFilters(undefined);
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.delete("search");
  };

  const handleSearch = (searchValue: string) => {
    const currentParams = new URLSearchParams(window.location.search);
    
    if (!searchValue) {
      // Reset search filters while keeping other filters
      setFilterGroup(prev => ({
        ...prev,
        value: prev.value.filter(group => group.operator === LogicalOperatorEnum.AND)
      }));
      currentParams.delete("search");
      navigate(`${window.location.pathname}?${currentParams.toString()}`);
      return;
    }
  
    // Update search parameter
    currentParams.set("search", searchValue);
    navigate(`${window.location.pathname}?${currentParams.toString()}`);
  
    setFilterGroup(prev => {
      const searchGroup: FilterGroup = {
        operator: LogicalOperatorEnum.OR,
        value: [
          {
            fieldName: "Translations.Name",
            operator: FilterOperatorEnum.CONTAINS,
            value: searchValue
          },
          {
            fieldName: "mainItemNo",
            operator: FilterOperatorEnum.CONTAINS,
            value: searchValue
          }
        ]
      };
  
      // Keep AND filters and add/update OR search filters
      return {
        operator: LogicalOperatorEnum.AND,
        value: [
          ...prev.value.filter(group => group.operator === LogicalOperatorEnum.AND),
          searchGroup
        ]
      };
    });
  };
  // Work task logic
  const handleWorkTaskChange = async (worktask?: WorkTask) => {
    setInitWorkTaskLoaded(true);
    if (!worktask) {
      setFilterGroup(getDefaultFilters());
      setDefaultWorkTaskId(undefined);
      setWorkTaskHasNoFilters(undefined);
      return;
    }

    let workTaskFilterGroup = parseStringToFilters(worktask?.queryString ?? "");
    const workTaskFiltersArr = workTaskFilterGroup.value as FilterItem[];
    const productIds = worktask?.items?.map(item => item?.productId ?? 0) ?? [];

    const filterToModify = {...filterGroup};

    if(workTaskFiltersArr.length > 0){
      if(filterToModify.value.length > 1){
        const newFilter = { operator: worktask.queryType == 0 ? LogicalOperatorEnum.AND : LogicalOperatorEnum.OR, value: workTaskFiltersArr } as FilterGroup
        filterToModify.value[1] = newFilter;
      } else {
        filterToModify.value.push({ operator: worktask.queryType == 0 ? LogicalOperatorEnum.AND : LogicalOperatorEnum.OR, value: workTaskFiltersArr } as FilterGroup);
      }
    }else{
      if(filterToModify.value.length > 1){
        filterToModify.value[1].value = [];
      }
    }

    if(productIds.length > 0){
      const productStaticFilterItems = [{ fieldName: "id", operator: FilterOperatorEnum.IN, value: productIds.join(",") }] as FilterItem[];
      let currentDefaultFilterItems = filterToModify.value[0].value as FilterItem[];
      if(currentDefaultFilterItems.find((item: FilterItem) => item.fieldName === "id")){
        currentDefaultFilterItems = currentDefaultFilterItems.filter((item: FilterItem) => item.fieldName !== "id");
        filterToModify.value[0].value = [...currentDefaultFilterItems, ...productStaticFilterItems];
      }else{
        filterToModify.value[0].value = [...productStaticFilterItems];
      }
    }

    if(productIds.length == 0){
      if(filterToModify.value.length > 1){
        filterToModify.value[0].value = (filterToModify.value[0].value as FilterItem[]).filter((item: FilterItem) => item.fieldName !== "id");
      }
    }

    setFilterGroup(filterToModify);
    setWorkTaskHasNoFilters(workTaskFiltersArr?.length === 0 && productIds.length === 0);
    setDefaultWorkTaskId(worktask?.id);
  };

  // Columns
  const columns = useMemo(
    () => getProductColumns(activeLanguage),
    [activeLanguage]
  );

  const onTabChangeHandler = (value: number) => {
    const filterGroupCopy = {...filterGroup};
    const filterItems = filterGroupCopy.value[0].value as FilterItem[];
    const productStatusFilter = filterItems.find((item: FilterItem) => item.fieldName === "productStatus");
    if (productStatusFilter) {
      productStatusFilter.value = value.toString();
    }
    setFilterGroup(filterGroupCopy);
  };

  return (
    <StyledCard bordered={false} className="criclebox h-full">
      {/* <pre>{JSON.stringify(filterGroup, null, 2)}</pre> */}
      <TableTitle level={4}>Produkter</TableTitle>
      <TableHeader style={{ position: "relative" }}>
        <div className="top-header">
          <ProductStatusFilterTabs
            metadata={metadata}
            filterItems={filterGroup.value.find(item => item.operator === LogicalOperatorEnum.AND)?.value as FilterItem[]}
            onTabChange={onTabChangeHandler}
          />
        </div>
        <div className="sub-header">
          <Search
            placeholder="Sök produkt..."
            onSearch={handleSearch}
            allowClear
            enterButton={true}
          />
        </div>

        <div className="sub-header">
            <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <span className="selected-products-count">
              {selectedRowKeys?.length ? (
                <Badge
                  className="site-badge-count-109"
                  count={selectedRowKeys?.length}
                  overflowCount={100}
                  style={{
                    backgroundColor: "#F6FFED",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    color: "black",
                  }}
                />
              ) : (
                <span className="custom-badge-empty">0</span>
              )}
            </span>
            <Dropdown.Button
              menu={{ items: menuItems, onClick: onMultiButtonClick }}
              disabled={selectedRowKeys?.length == 0}
              onClick={showTranslateContextModal}
            >
              Generera texter
            </Dropdown.Button>
            <TopLabel
              text={workTaskHasNoFilters ? "Listan har inga produkter" : ""}
              type={workTaskHasNoFilters ? "error" : "info"}
            >
              <WorkTaskSelect
                defaultWorkTaskId={defaultWorkTaskId}
                workTaskType={0}
                onSelectedWorkTask={handleWorkTaskChange}
              />
            </TopLabel>
            </div>
              
            <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <Button
                disabled={filterGroup?.value?.length == 0}
                style={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                }}
                type="primary"
                icon={<XCircle size={"1rem"} />}
                onClick={clearFilter}
              >
                <span style={{ marginTop: "2px" }}>Rensa Filter</span>
              </Button>{" "}
              <Button
                style={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                }}
                type="primary"
                icon={<RefreshCcw size={"1rem"} />}
                onClick={() => setKassanovaFetchModalOpen(true)}
              >
                <span style={{ marginTop: "2px" }}>Hämta produkter</span>
              </Button>{" "}
            </div>
        </div>
      </TableHeader>

      <SimpleListView<Product>
        queryKey={PRODUCTS_QUERY_KEY}
        resizableColumns={false}
        defaultSorting={{ fieldName: "createdAt", sortOrder: SortOrderEnum.DESC }}
        selectable={true}
        onRowClick={(item) => {
          navigate(`/products/edit/${item.id ?? ""}`);
        }}
        onSelectionChange={(selectedItems) => {
          onSelectChange(selectedItems);
        }}
        fetchItems={async (
          page,
          pageSize,
          sortOrder,
          orderByField,
          filterString
        ) => {
          return await getProducts({
            page: page,
            pageSize: pageSize,
            order: sortOrder,
            orderByField: orderByField,
            filters: filterString,
          });
        }}
        columns={columns}
        renderHeader={() => {
          return <React.Fragment></React.Fragment>;
        }}
        filterGroup={filterGroup}
        listLoadingHeightPx={DEFAULT_LIST_COL_HEIGHT_PX * 10}
      />
      <ImportFileModal
        modalProps={importModalProps}
        onFinish={onImportModalFinish}
      ></ImportFileModal>
      <MultiGenerateTextModal
        modalProps={translateContextModalProps}
        onFinish={onTextRewriteFinish}
      ></MultiGenerateTextModal>
      <MultiEditProductsModal
        modalProps={multiEditProductsModalProps}
        onFinish={onMultiEditProductsModalFinish}
        selectedIds={selectedRowKeys}
      ></MultiEditProductsModal>
      <KassanovaFetchModal
        isOpen={kassanovaFetchModalOpen}
        onClose={() => setKassanovaFetchModalOpen(false)}
      ></KassanovaFetchModal>
    </StyledCard>
  );
};
